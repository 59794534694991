*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.login-container {
	background-color: rgb(247, 247, 247);
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-card {
	background-color: white;
	width: min(100%, 700px);
	display: flex;
	padding: 30px;
	justify-content: center;
	box-shadow: 2px 2px 2px #0084b083;
	border: 1px solid #0084b034;
	background-color: white;
}

.login-card > form > div {
	padding: 10px;
	margin: 20px 0px;
	letter-spacing: 2px;
	font-size: 20px;
	color: #0084b0ce;
	display: flex;
}
.login-card > form {
	width: 100vw;
	max-width: 700px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-forms {
	padding: 5px 5px;
	font-size: 20px;
	border: 1px solid #00526d46;
	box-shadow: 0px 0px 1px black;
	width: 100%;
}

.label-login {
	width: min(300px, 90vw);
}

.login-button {
	padding: 10px 20px;
	font-size: 20px;
	background-color: #11aee2;
	border: 2px solid #1693bd;
	border-radius: 5px;
	color: rgb(240, 240, 240);
	letter-spacing: 2px;
	cursor: pointer;
}

.login-button:hover {
	background-color: #00779e;
	border: 2px solid #003b4e;
}

.login-button:active {
	background-color: #006283;
	border: 2px solid #002936;
}

.error-login {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
}

.error-login > div {
	min-width: 30vw;
	min-height: 10vh;
	background-color: rgb(54, 0, 0);
	border-radius: 5px;
	font-size: min(35px, 5vw);
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgb(209, 90, 90);
	padding: 5px 15px;
}
