:root {
	--clr-gradient-1: #00b4db;
	--clr-gradient-2: #0083b0;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.popUp-plate {
	position: absolute;
	z-index: 5;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.popUp-container {
	border: 3px solid var(--clr-gradient-2);
	border-radius: 15px;
	background-color: white;
	overflow: hidden;
	min-width: 80vw;
}

.popUp-exit {
	min-height: 30px;
	color: white;
	display: flex;
	justify-content: flex-end;
	background-color: var(--clr-gradient-2);
}

.popUp-exit > div {
	padding: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.popUp-exit > div:hover {
	background-color: white;
	color: var(--clr-gradient-2);
}

.popUp-menu {
	min-height: 10vh;
	background-color: var(--clr-gradient-2);
	border-top: 1px solid white;
	padding: 5px 10px;
	font-size: min(30px, 5vw);
	color: white;
	letter-spacing: 2px;
	display: flex;
}

.popUp-menu-inner {
	flex: 1;
}

.popUp-menu-inner > span {
	margin: 20px;
	white-space: nowrap;
}

.no-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.popUp-button {
	background-color: white;
	border: 1px solid var(--clr-gradient-1);
	padding: 10px 25px;
	color: var(--clr-gradient-2);
	border-radius: 10px;
	margin: 5px 10px;
	font-size: 25px;
	font-weight: bold;
	letter-spacing: 2px;
	cursor: pointer;
}

.popUp-button:hover {
	border: 1px solid #00a0c4;
	background-color: rgba(255, 255, 255, 0.8);
	color: #005d7c;
}

.popUp-button:active {
	border: 1px solid #0094b6;
	background-color: rgba(255, 255, 255, 0.7);
	color: #00445a;
}

.popUp-text {
	white-space: nowrap;
	padding: 15px;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 35vh 35vh;
}

.popUp-text > div > h4 {
	text-align: center;
}

.popUp-text > div {
	border: 1px solid rgba(128, 128, 128, 0.5);
	text-align: left;
	padding: 0px 5px;
	letter-spacing: 1px;
	overflow: auto;
}

.sms-textArea {
	width: 100%;
	height: 75%;
	resize: none;
	font-size: 20px;
	letter-spacing: 1px;
	padding: 5px;
	line-height: 1.2;
}

.sms-center {
	text-align: center;
}

.note-textarea {
	width: 78vw;
	height: 70vh;
	font-size: 20px;
	letter-spacing: 1px;
	padding: 5px;
	line-height: 1.5;
	resize: none;
}
