:root {
	--clr-gradient-1: #00b4db;
	--clr-gradient-2: #0083b0;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

nav {
	width: 100%;
	background-color: rgb(248, 248, 248);
	height: 10vh;
	display: flex;
	border-bottom: 2px solid var(--clr-gradient-2);
	position: relative;
	align-items: center;
}

.navbar-link {
	width: max(100px, 10%);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--clr-gradient-2);
	border-right: 2px solid var(--clr-gradient-2);
	height: 100%;
	cursor: pointer;
}

.navbar-link:hover {
	color: white;
	background-color: var(--clr-gradient-2);
}

.logout {
	position: absolute;
	right: 5%;
	color: var(--clr-gradient-2);
	border: 2px solid var(--clr-gradient-2);
	border-radius: 5px;
	padding: 5px 10px 5px 5px;
	cursor: pointer;
}

.logout-icon {
	vertical-align: middle;
	margin-right: 5px;
	color: var(--clr-gradient-2);
}

.logout:hover,
.logout:hover .logout-icon {
	background-color: var(--clr-gradient-2);
	color: white;
}

.balance {
	font-size: 30px;
	border-radius: 5px;
	text-align: center;
}
