:root {
	--clr-gradient-1: #00b4db;
	--clr-gradient-2: #0083b0;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #00b4db; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#00b4db,
		#0083b0
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#00b4db,
		#0083b0
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
